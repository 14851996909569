@import "src/assets/css/_base.scss";

.user-message {
    align-self: flex-end;
    background-color: $col_blue;
    color: $col-white;
}

.ai-message {
    align-self: flex-start;
    background-color: $col-gray-100;
}

.error-message {
    background-color: $col-red;
    color: $col-white;
    opacity: 0.8;
}

.message-container {
    max-width: 70%;
    padding: rem(12) rem(16);
    border-radius: rem(12);
    word-break: break-word;
    font-size: rem(14);

    .loading-message {
        display: inline-block;
        width: 100%;
        animation: pulse-text 2s infinite ease-in-out;
    }

    .pulsating-text {
        animation: pulse-text 2s infinite ease-in-out;
        display: inline-block;
    }

    .markdown {

        // Reset margins for markdown content
        * {
            margin: 0;
            padding: 0;
        }


        // Add spacing between elements
        p,
        ul,
        ol,
        blockquote,
        pre {
            margin-bottom: rem(16);

            &:last-child {
                margin-bottom: 0;
            }
        }

        // Style lists
        ul,
        ol {
            padding-left: rem(20);
        }

        // Style links
        a {
            color: $col_blue;
            text-decoration: underline;

            &:hover {
                opacity: 0.8;
            }
        }

        // Style code blocks
        .code-block {
            background: $col-gray-750;
            border-radius: rem(6);
            padding: rem(12);
            margin: rem(8) 0;
            overflow-x: auto;

            code {
                color: $col-white;
                font-family: monospace;
                font-size: rem(13);
                line-height: 1.4;
                white-space: pre;
            }
        }

        // Style inline code
        .inline-code {
            background: $col-gray-200;
            padding: rem(2) rem(4);
            border-radius: rem(4);
            font-family: monospace;
            font-size: rem(13);
        }

        // Style blockquotes
        blockquote {
            border-left: rem(4) solid $col-gray-300;
            padding-left: rem(16);
            color: $col-gray-600;
        }

        // Style tables
        table {
            border-collapse: collapse;
            width: 100%;
            margin: rem(8) 0;

            th,
            td {
                border: 1px solid $col-gray-300;
                padding: rem(8);
                text-align: left;
            }

            th {
                background: $col-gray-100;
            }
        }
    }

    .message-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: rem(8);

        button {
            border: 1px solid $col_blue;
            border-radius: rem(4);
            padding: rem(4) rem(8);
            color: $col_white;
            background-color: $col_blue;
            font-size: rem(12);

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

@keyframes pulse-text {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}