@import 'BaseAttachmentRenderer.module.scss';

.doc-renderer {
    padding: rem(2);
    height: 100%;
}

.doc-renderer-hidden {
    visibility: hidden;
}
