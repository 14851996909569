@import "assets/css/_base.scss";

.wrap {
  h3 {
    font-weight: $fw_5;
    font-size: rem(18);
    line-height: rem(24);
    color: $col_gray-600;
    margin-bottom: rem(16);
  }
  
  .form-group {
    width: 100%;
    margin-right: rem(24);
  
    &:last-child {
      margin-right: 0;
    }
  
    label {
      margin-bottom: rem(10);
    }
  
    .form-required {
      color: $col_red;
    }
  }

  .settings-checkbox {
    margin-top: rem(8);
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: rem(16);
    margin-top: rem(16);

    :global(.btn-common) {
      width: auto;
      margin-top: 0;
    }
  }
  
  .beat-loader {
    width: 100%;
    height: rem(40);
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }
}
