@import "assets/css/base";

.general-rw-wrap {
  .general-rw-head {
    .general-rw-head-control {
      margin: rem(8) 0 rem(8) 0;
      display: flex;
      align-items: center;

      .general-rw-head-option {
        display: flex;
        align-items: center;
        gap: rem(16);
      }
    }

    .general-rw-head-control-single-item {
      justify-content: flex-end;
    }

    .general-rw-head-control-multi-item {
      justify-content: space-between;
    }
  }

  .general-rw-body {
    display: flex;
    height: calc(130vh - 180px);

    .general-rw-quick-select {
      width: 23%;
      padding-right: rem(32);
      display: flex;
      flex-direction: column;

      .quick-select-body {
        flex: 1;
        overflow: auto;
        background: $col_white;
        box-shadow: $box_shadow;
        border-radius: rem(8);
      }
    }

    .general-rw-search-result {
      flex: 1;
      width: 77%;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    .general-rw-result-info-and-preview {
      margin-top: rem(32);
      display: flex;
      position: relative;
      flex: 1;
      min-height: 0;

      .general-rw-result-info {
        width: 40%;
        padding-right: rem(32);
        display: flex;
        flex-direction: column;
        min-height: 0;

        :global(.is-result-info-wrap) {
          flex: 1;
          min-height: 0;
        }

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;
          height: 100%;

          .rw-title-wr {
            display: flex;
            justify-content: space-between;

            .rw-title {
              display: flex;
              align-items: center;

              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }

              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }

      .general-rw-result-preview {
        width: 60%;
        display: flex;
        flex-direction: column;
        min-height: 0;

        :global(.is-result-preview-wrap) {
          flex: 1;
          min-height: 0;
        }
      }
    }

    .full-table {
      margin-top: rem(32);
      position: relative;

      .general-rw-result-info {
        width: 100%;

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;

          .review-info {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 20% 25% 32% auto;
          }

          .rw-title-wr {
            @include flex-between;

            .rw-title {
              display: flex;
              align-items: center;

              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }

              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }

      .general-rw-result-preview {
        width: 100%;
      }
    }
  }
}

@media print {
  .general-rw-wrap {
    height: 0;
    zoom: 92%;
  }

  .general-rw-head {
    .general-rw-head-control {
      margin-bottom: rem(16) !important;
    }
  }

  .general-rw-body {
    .general-rw-quick-select {
      width: 23% !important;
      padding-right: rem(15) !important;
    }

    .general-rw-search-result {
      width: 77% !important;
    }
  }

  :global(.quick-select__content) {
    padding: rem(16) rem(10) !important;
  }
}