@import "assets/css/base";

.border-wrap {
  box-shadow: $box_shadow;
  border-radius: rem(8);
  padding: rem(14);
}

.email {
  &-wrap {
    background: $col_white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .email-result-preview {
      &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }

      &-title {
        font-weight: $fw_5;
        font-size: rem(18);
        color: $col_gray-600;
        padding-right: rem(16);
        display: flex;
        align-items: center;

        img {
          margin-bottom: rem(2);
        }
      }

      &-select {
        display: flex;
        align-items: center;

        &-filter {
          .search-wrapper .btn-input {
            height: rem(28);
            width: vw(187);
            font-size: rem(14);
            padding-right: rem(25);
          }

          .icon-search,
          .icon-clear {
            width: rem(16);
            height: rem(16);
          }
        }
      }

      &-search {
        display: flex;
        input {
          height: rem(28) !important;
        }
      }
    }

    .show-filter-control {
      margin-bottom: rem(35) !important;
    }

    .message-detail { 
      background-color: $col_gray-300;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      margin-top: rem(10);
      border: rem(1) solid $col_gray-800;
      box-shadow: none !important;
      border-radius: rem(8);
      position: relative;
      height: 100%;

      .message-detail-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .message-body {
        padding: rem(10);
        height: 100%;
        position: absolute;
        bottom: rem(15);
        width: 100%;
      }
    }
    .empty-page {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: $col_white;
    }

    .message-detail {
      @include scroll-flexible;
    }
    .tag-select {
      margin-left: rem(12);
    }
  }

  &-preview {
    padding: 0;

    .message-detail {
      min-height: auto;

      .message-body {
        width: 100%;
      }
    }
  }
}
