@import "assets/css/_base.scss";

.modal-container {
  .modal-dialog {
    min-width: rem(432);

    .modal-content {
      padding: rem(24) 0;
      border-radius: rem(8);

      .modal-header {
        border: none;
        padding: 0 rem(24);
        div {
          font-size: rem(24);
          font-weight: $fw_5;
          color: $col_gray-600;
        }
        button {
          border: rem(1) solid $col_gray-600;
          opacity: 1;
          margin: 0;
          position: absolute;
          top: rem(16);
          right: rem(16);
        }
      }

      .modal-body {
        padding: rem(24) rem(24) rem(32);

        label {
          font-size: rem(14);
          margin-bottom: rem(8);
        }

        :global(.field-select__control) {
          height: rem(48);
          border-radius: rem(6);
          margin-bottom: rem(24);
        }

        .field-input {
          input {
            height: rem(48);
            border-radius: rem(6);
          }
        }

        .ai-capabilities-section {
          display: flex;
          flex-direction: column;
          gap: rem(16);

          .section-label {
            font-size: rem(14);
            font-weight: $fw_5;
            color: $col_gray-600;
          }

          .toggle-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .note-tooltip-group {
              position: relative;
              display: flex;
              align-items: center;

              span {
                font-size: rem(14);
                color: $col_gray-600;
              }

              .note-tooltip {
                margin: 0 0 rem(4) rem(6);
                width: rem(12);
              }

              .tooltip-description {
                position: absolute;
                top: calc(100% + rem(2));
                left: 0;
                display: none;
                background: $col_gray-750;
                box-shadow: $box_shadow;
                border-radius: rem(4);
                width: rem(420);
                min-height: rem(24);
                padding: rem(16);
                z-index: 99;
                color: $col_white;
                font-weight: $fw_4;
                font-size: rem(14);
              }

              &:hover > .tooltip-description {
                display: block;
              }
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: rem(24) rem(24) 0;

        > * {
          margin: 0;
        }

        .group-btn {
          display: flex;
          gap: rem(16);
        }
      }
    }
  }
}
