@import "assets/css/_base.scss";


.wrap {
  .no-result {
    @include flex-center;
    margin: rem(12) 0;
    font-weight: $fw_4;
    font-size: rem(16);
    color: $col_gray-400;
  }

  > div {
    margin-bottom: rem(16);
  }
  
  ul {
    max-height: rem(170);
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll_flexible;
  
    li {
      font-size: rem(16);
      line-height: rem(24);
      font-weight: $fw_4;
      color: $col_gray-600;
      margin-bottom: rem(10);
      @include text_ellipsis;
  
      &:hover {
        font-weight: $fw_7;
        cursor: pointer;
      }
  
      &:last-child {
        margin-bottom: 0;
      }

      &.group {
        margin-bottom: rem(16);
        
        &:hover {
          font-weight: $fw_4;
          cursor: default;
        }

        .group-header {
          font-weight: $fw_6;
          font-size: rem(14);
          text-transform: uppercase;
          margin-bottom: rem(8);
          padding-left: rem(8);
        }

        .group-options {
          max-height: none;
          
          li {
            padding-left: rem(16);
            
            &:hover {
              font-weight: $fw_7;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    .active {
      font-weight: $fw_7;
    }
  }
}