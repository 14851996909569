@import "assets/css/base";

.is-wrap {
  .is-head {
    .is-head-control {
      margin: rem(8) 0 rem(8) 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .is-head-print {
        display: flex;
        align-items: center;
        gap: rem(10);
      }
    }
  }

  .is-body {
    display: flex;
    height: calc(130vh - 180px);
    min-height: 0;

    .is-quick-select {
      width: 23%;
      padding-right: rem(32);
      display: flex;
      flex-direction: column;
      min-height: 0;

      :global(.quick-select__content) {
        flex: 1;
        overflow: auto;
        background: $col_white;
        box-shadow: $box_shadow;
        border-radius: rem(8);
      }
    }

    .is-search-result {
      flex: 1;
      width: 77%;
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    .is-result-info-and-preview {
      margin-top: rem(32);
      display: flex;
      position: relative;
      flex: 1;
      min-height: 0;

      .is-result-info {
        width: 40%;
        padding-right: rem(32);
        display: flex;
        flex-direction: column;
        min-height: 0;

        :global(.is-result-info-wrap) {
          flex: 1;
          min-height: 0;
        }

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;

          .rw-title-wr {
            @include flex-between;

            .rw-title {
              display: flex;
              align-items: center;
              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }
          
              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }

      .is-result-preview {
        width: 60%;
        display: flex;
        flex-direction: column;
        min-height: 0;

        :global(.is-result-preview-wrap) {
          flex: 1;
          min-height: 0;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .full-table {
      margin-top: rem(32);
      position: relative;

      .is-result-info {
        width: 100%;

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;
          display: flex;
          flex-direction: column;

          .review-info {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 20% 25% 32% auto;
          }

          .rw-title-wr {
            @include flex-between;

            .rw-title {
              display: flex;
              align-items: center;
              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }
          
              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }

      .is-result-preview {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 0;
      }
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .is-body {
      display: table;

      .is-quick-select,
      .is-search-result {
        display: table-cell;
      }

      .is-quick-select {
        padding-right: rem(12) !important;
      }
    }
  }
}
