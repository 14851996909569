@import "assets/css/base";

.modal-container {
  .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: none;
    padding: rem(16) !important;
    position: relative;

    .modal-header-right {
      display: flex;
      align-items: center;

      .modal-header-title {
        color: $col_gray-600;
        font-weight: $fw_5;
        font-size: rem(18);
        line-height: rem(32);
      }

      .modal-header-record {
        font-size: rem(12);
        font-weight: $fw_4;
        color: #a1a8b2;
        margin: 0 rem(10);
      }
    }

    .header-close {
      position: absolute;
      top: rem(16);
      right: rem(16);
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 0 rem(16) !important;

    .is-result-info-and-preview {
      max-height: rem(500);
      margin-top: rem(9);
      display: flex;

      .is-result-info {
        width: 40%;
        padding-right: rem(32);

        :global(.card-body) {
          overflow-y: auto;
          @include scroll-flexible;
        }
      }

      .is-result-preview {
        width: 60%;
      }
    }
  }

  .modal-footer {
    padding: rem(20) rem(16) !important;
    margin-top: rem(24);
    display: flex;

    & > * {
      margin: 0;
    }

    button {
      margin-left: rem(16) !important;
    }
  }

  :global(.modal-dialog) {
    max-width: rem(1280);
    margin: auto;
    height: 100%;
    @include flex-center;
  }

  @media only screen and (min-width: 3840px) {
    :global(.modal-dialog) {
      height: auto;
      margin-top: rem(160);
    }
  }
}
