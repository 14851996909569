@import "assets/css/base";

.is-result-info-wrap {
  background: $col_white;
  box-shadow: $box_shadow;
  border-radius: rem(8);
  height: 100%;

  .is-result-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: rem(16);

    .is-info-wrap {
      background: $col_white;
      padding: rem(4);
      margin-top: rem(10);
      flex: 1;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;

      .loading-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .result-info-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
    }

    .is-result-info-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      h5 {
        font-weight: $fw_5;
        font-size: rem(18);
        color: $col_gray-600;
        line-height: rem(24);
      }
    
      .record-control {
        align-self: flex-end; 
      }
    
      span {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(24);
    
        &::before {
          content: "";
          margin: rem(6);
          height: rem(24);
          width: rem(1);
          border: solid rem(1) $col_gray-600;
        }
      }
    }    

    .is-result-chat-title {
      font-size: rem(14);
      margin-top: rem(12);
    }
  }
}

.is-result-card-preview {
  min-height: fit-content !important;
  height: 100% !important;
}

.info-wrap {
  box-shadow: none;
  border: rem(1) solid rgba(211, 211, 211, 0.5);
}

@media print {
  .is-result-info-wrap {
    .is-info-wrap {
      overflow: hidden !important;
    }
  }
}
