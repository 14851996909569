@charset "utf-8";

$sp_w: 768px;
$pc_w: 769px;
$design_width: 1920;

$config_base_fontsize: 10;

$height-header: 96px;

/*_____ font _______________*/
@function rem($font_size_psd) {
  $rem: calc($font_size_psd / $config_base_fontsize);
  @return #{$rem}rem;
}

@function vw($design_width_psd) {
  $vw: calc($design_width_psd / $design_width * 100);
  @return #{$vw}vw;
}

/*_____ color _______________*/
$col_white: #ffffff;
$col_black: #000000;

//- pager
$col_green: #229d25;
$col_green-200: #a2d468;
$col_green-300: #62b58f;
$col_green-600: rgba(98, 181, 143, 0.15);
$col_blue: #3665b0;
$col_blue-15: rgba(21, 128, 239, 0.15);
$col_blue-1: #157eef;
$col_blue-op-10: rgba(#1580ef, 0.1);
$col_blue-2: rgba(21, 128, 239, 0.24);
$col_blue-3: rgba(21, 128, 239, 0.09);
$col_blue-4: rgba(21, 128, 239, 0.5);
$col_blue-10: #007df2;
$col_blue-100: #35c0ed;
$col_blue-200: #67c6e2;
$col_blue-600: rgba(21, 128, 239, 0.1);
$col_turquoise: #2ce0c4;
$col_turquoise-200: #35d8bb;
$col_flower: #d981ef;
$col_flower-200: #bf8af1;
$col_pink: #fd9fb7;
$col_yellow: #fff89d;
$col_yellow-100: #ffff00;
$col_yellow-200: #fdc75f;
$col_gray: #8a8a8a;
$col_gray-10: #f1f1f1;
$col_gray-20: #f3f3f3;
$col_gray-30: #eeeeee;
$col_gray-50: #f9f9f9;
$col_gray-70: #eff4f9;
$col_gray-100: #f5f5f5;
$col_gray-200: #c7c7c7;
$col_gray-250: #f0f0f0;

$col_gray-300: #d9d9d9;
$col_gray-400: #707070;
$col_gray-500: #e5e5e5;
$col_gray-550: rgba(138, 138, 138, 0.15);
$col_gray-600: #222222;
$col_gray-700: #d3d3d3;
$col_gray-750: rgba(34, 34, 34, 0.8);
$col_gray-800: rgba(211, 211, 211, 0.5);
$col_gray-850: #d9d9d9;
$col_gray-900: rgba(0 0 0 / 15%);
$col_red: #d90000;
$col_red-2: #e12121;
$col_red-3: #ff4500;
$col_red-4: #fd6561;
$col_gray-3: grey;
$col_gray-4: #e9e9e9;
$col_box_shadow: #00000014;
$col_orange: #ef4915;
$col_liberty: #5d62b5;
$col_blue_green: #29c3be;
$col_begonia: #f2726f;
$col_sunglow: #ffc533;
$col_sunglow-15: rgba(255, 197, 51, 0.15);
$col_polished_pine: #62b58f;
$col_maya_blue: #67cdf2;
$col_dark-charcoal: #333333;
$col_dark-jeans: #5ba9f9;
$bg_color: #fbfbfb;
$color_lavender: #e3eef9;
$color_feta: #eafdf5;
$color_fantasy: #fef1f1;
$col_black_pearl: #031426;

// StreemView Branding Colors
$col_branding_fucsia: #eb3291;
$col_branding_baby_blue: #77d0ec;
$col_branding_denim_blue: #3665b0;
$col_branding_streem_blue: #051e3c;

$box_shadow: 0 rem(4) rem(12) rgba(0, 0, 0, 0.15);
$box_shadow_fade: 0 rem(4) rem(24) rgba(0, 0, 0, 0.08);
$box_shadow_icon: 0 rem(4) rem(8) rgba(0, 0, 0, 0.08);

$background-faded: rgba($col_white, 0.5);

$linear-1: linear-gradient(white, white);
$linear-2: linear-gradient(
  -220deg,
  #5d62b5,
  #29c3be,
  #ffc533,
  #f2726f,
  #67cdf2
);

/*_____ class color _______________*/

.bg-blue {
  background-color: #1580ef !important;
}

.bg-orange {
  background-color: #ef7115 !important;
}

.bg-green {
  background-color: #26ef15 !important;
}

.bg-red {
  background-color: #ef153c !important;
}

.bg-bright-blue {
  background-color: #15efe2 !important;
}

.bg-sky-blue {
  background-color: #15c8ef !important;
}

.bg-squash {
  background-color: #efb215 !important;
}

/*_____ font weight _______________*/
$fw_3: 300;
$fw_4: 400;
$fw_5: 500;
$fw_6: 600;
$fw_7: 700;
$fw_8: 800;
$fw_b: bold;
$fw_n: normal;

/*_____ Mixin _______________*/

@mixin trans($timer) {
  transition: all $timer + s 0s ease;
}

@mixin hov {
  transition: all 0.3s 0s ease;
  &:hover {
    opacity: 0.75;
  }
}

@mixin table_style {
  display: table;
  width: 100%;
  > * {
    display: table-cell;
  }
}

@mixin table_style_pos($pos) {
  display: table;
  width: 100%;
  > * {
    display: table-cell;
    vertical-align: $pos;
  }
}

@mixin clearfix {
  /* For IE 6/7 only */
  *zoom: 1;

  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin font_default {
  font-family: "Helvetica Neue", sans-serif;
}

@mixin abs($left, $right) {
  position: absolute;
  top: 0;
  left: $left;
  right: $right;
}

@mixin insert_item($space_right) {
  position: relative;
  padding-left: $space_right;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin text-center {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}

@mixin scroll-horizontal {
  &::-webkit-scrollbar-track {
    border-radius: rem(10);
    background-color: unset;
  }

  &::-webkit-scrollbar {
    width: rem(2);
    height: rem(4);
    background-color: unset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(10);
    background-color: $col_blue;
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin firefox-border($width) {
  @-moz-document url-prefix() {
    border-width: rem($width);
  }
}

@mixin scroll-flexible {
  &::-webkit-scrollbar-track {
    border-radius: rem(10);
    background-color: unset;
  }

  &::-webkit-scrollbar {
    width: rem(6);
    height: rem(6);
    background-color: unset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(10);
    background-color: $col_blue;
  }

  @media screen and (min-width: 1920px) and (max-width: 2559px) {
    &::-webkit-scrollbar {
      width: rem(6);
    }
  }
  @media screen and (min-width: 2560px) and (max-width: 3839px) {
    &::-webkit-scrollbar {
      width: rem(8);
    }
  }
  @media screen and (min-width: 3840px) {
    &::-webkit-scrollbar {
      width: rem(10);
    }
  }
}

@mixin scroll-flexible-horizontal {
  &::-webkit-scrollbar-track {
    border-radius: rem(10);
    background-color: unset;
  }

  &::-webkit-scrollbar {
    height: rem(6);
    background-color: unset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(10);
    background-color: $col_blue;
  }

  @media screen and (min-width: 1920px) and (max-width: 2559px) {
    &::-webkit-scrollbar {
      height: rem(6);
    }
  }
  @media screen and (min-width: 2560px) and (max-width: 3839px) {
    &::-webkit-scrollbar {
      height: rem(8);
    }
  }
  @media screen and (min-width: 3840px) {
    &::-webkit-scrollbar {
      height: rem(10);
    }
  }
}
