@import "assets/css/base";

.wrap {
  position: relative;
  background: $col-white;
  box-shadow: $box_shadow_icon;
  border-radius: rem(8);
  padding: rem(14) rem(16);
  height: 100%;

  .date-range-control {
    margin-bottom: rem(16);
    width: rem(234);
    height: rem(62);

    .date-range-label {
      color: $col_gray-600;
      font-weight: fw-5;
      font-size: rem(12);
      margin-bottom: rem(6);
    }

    .date-range-container {
      width: inherit;
      height: rem(38);
      position: relative;
      font-weight: fw-4;
      font-size: rem(14);
      color: $col_gray-600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #FFFFFF;
      border-color: #CCCCCC;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: rem(2) rem(8);

      .date-range-text {
        flex-grow: 1;
      }

      .gray-text {
        color: #808080;
      }        

      .date-range-icon {
        display: flex;
        align-items: center;
        height: rem(22);
        width: rem(30);
        border-left: 1px solid #CCCCCC;
        padding-left: rem(6);
      }
    }
  }


  .filter-control {
    @include flex-between;
    .title {
      font-weight: $fw_5;
      font-size: rem(18);
      color: $col_gray-600;
    }

    .btn-group {
      @include flex-center;

      .btn-reset {
        margin-right: rem(8);
      }
    }
  }

  .filter-contain {
    position: absolute;
    background: $col-white;
    border: rem(1) solid $col_gray-800;
    border-radius: rem(8);
    padding: rem(18) rem(16);
    top: rem(58);
    bottom: rem(16);
    left: rem(16);
    right: rem(16);
  }
}
