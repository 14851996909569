@import "src/assets/css/_base.scss";

.ai-history-dropdown {
    position: relative;

    .dropdown-toggle {
        background-color: $col-white;
        cursor: pointer;
        border: none;

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        img {
            width: rem(20);
            height: rem(20);
            transition: filter 0.2s ease;
        }

        &:hover img, &:active img {
            filter: invert(31%) sepia(98%) saturate(1753%) hue-rotate(201deg) brightness(97%) contrast(107%);
        }
    }

    .dropdown-menu {
        position: absolute;
        width: rem(250);
        top: 100%;
        right: 0;
        margin-top: rem(4);
        background-color: $col-white;
        border: 1px solid $col-gray-300;
        border-radius: rem(6);
        box-shadow: 0 rem(2) rem(4) rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }

    .search-container {
        position: relative;
        padding: rem(8);
    }

    .search-input {
        width: 100%;
        padding: rem(6) rem(8);
        border: 1px solid $col-gray-300;
        border-radius: rem(4);
        font-size: rem(14);

        &:focus {
            outline: none;
            border-color: $col-blue;
            box-shadow: 0 0 0 rem(2) rgba($col-blue, 0.1);
        }
    }

    .clear-search {
        position: absolute;
        right: rem(16);
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        color: $col-gray-500;
        font-size: rem(18);
        cursor: pointer;
        padding: rem(4);

        &:hover {
            color: $col-gray-700;
        }
    }

    .dropdown-items {
        max-height: rem(300);
        overflow-y: auto;
        @include scroll-flexible;
    }

    .dropdown-item {
        padding: rem(8) rem(12);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: rem(14);

        &:hover {
            background-color: $col-gray-100;
        }

        span {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: rem(8);
        }
    }

    .clear-chat-button {
        background: none;
        border: none;
        color: $col-gray-500;
        font-size: rem(18);
        line-height: 1;
        cursor: pointer;
        padding: rem(4);
        margin-left: rem(8);

        &:hover {
            color: $col-gray-700;
        }
    }

    .no-results {
        padding: rem(12);
        text-align: center;
        color: $col-gray;
        font-size: rem(14);
    }
}