@import 'BaseAttachmentRenderer.module.scss';

.extracted-text-container {
    height: 100%;
    width: 100%;

    .loading-spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no-content {
        padding: rem(8);
        height: 100%;
        overflow: auto;

        p {
            font-size: rem(18);
            text-align: center;
        }
    }

    .content {
        padding: 1px;
        height: 100%;
        
        .document-content {
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            height: 100%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            .virtualized-list {
                @include scroll-flexible;
                box-sizing: border-box;
                flex: 1;
                height: auto !important;
                max-height: 100%;
            }
        }
    }
}

.content-line {
    font-size: rem(14);
    line-height: 1.5;
    white-space: pre-wrap;
    word-break: break-word;
    padding: rem(8) rem(16);
    box-sizing: border-box;
}

:global {
    .search-highlight {
        background-color: rgb(0, 255, 255);
        padding: 2px 0;
    }

    .search-term-highlight {
        background-color: #ffff00;
        padding: 2px 0;
    }
}
