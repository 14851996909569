@import 'BaseAttachmentRenderer.module.scss';

.html-renderer {
    min-height: 100%;
    max-height: 100%;
    white-space: pre-wrap;
    padding: rem(2);
    overflow: auto;
    @include scroll-flexible;
}

.html-renderer-hidden {
    visibility: hidden;
}

:global(.e-richtexteditor, .e-content) {
    border: solid 1px #e0e0e0;
    border-radius: 0.4rem;
}

:global(.e-richtexteditor) {
    border: 0 !important;
}
