@import "src/assets/css/_base.scss";

.suggestion-wrap {
    color: $col_blue-100;
    font-weight: 600;
    cursor: pointer;
    font-size: rem(14);
    border-radius: rem(12);
    padding: rem(4) rem(8);
    border: 1px solid $col_blue-100;

    &:hover {
        color: $col_blue;
    }
}