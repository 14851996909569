@import "assets/css/base";

.project-admin-body {
  background: $col_white;
  min-height: rem(720);
  box-shadow: $box_shadow_icon;
  border-radius: rem(8);
  padding: rem(16) rem(24) rem(26);
  position: relative;
  display: flex;
  flex-direction: column;

  .project-table-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no-result {
    align-items: center;
    display: flex;
    flex: 1;
  }

  .project-table-count {
    font-style: normal;
    font-weight: $fw_4;
    font-size: rem(14);
    line-height: rem(25);
    align-items: center;
    flex-grow: 1;
    color: $col_gray-400;
  }

  .table-project-management {
    margin-top: rem(17);
    min-height: rem(770);
    border: rem(1) solid $col_gray-800;
    border-radius: rem(8);
    flex: 1;
    background-color: $col_white;
    box-sizing: border-box;
  }

  table {
    width: 100%;
    height: auto;

    thead {
      width: 100%;
      height: rem(48);
      box-sizing: border-box;
      background: $col_gray-50;
      display: table-row-group;
      border-bottom: rem(1) solid $col_gray-800;

      th {
        padding: rem(10);
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: rem(8);
        }

        &:last-child {
          border-top-right-radius: rem(8);
        }
      }

      span {
        font-size: rem(12);
        font-weight: $fw_7;
        color: $col_gray-400;
      }

      a {
        font-size: rem(12);
        font-weight: $fw_7;
        color: $col_gray-400;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
          content: "";
          margin-left: rem(5);
          width: 0;
          height: 0;
          border-left: rem(4) solid transparent;
          border-right: rem(4) solid transparent;
        }
      }

      :global(a.sort-asc::after) {
        border-top: none;
        border-bottom: rem(6) solid $col_gray-600;
      }

      :global(a.sort-desc::after) {
        border-bottom: none;
        border-top: rem(6) solid $col_gray-600;
      }
    }

    tbody {
      tr {
        height: rem(48);
        width: 100%;
        font-size: rem(12);

        td {
          padding: 0 rem(10);
          color: $col_gray-600;
        }

        .table-project-name {
          color: $col_blue;
        }

        .table-project-disabled {
          color: $col_gray-200;
          pointer-events: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .table-project-user {
          display: flex;
          justify-content: flex-start;
          
          .member-list ul {
            bottom: unset;
            top: calc(100% + 0.8rem);
          }
        }

        .delete-date {
          color: $col_begonia;
          font-size: rem(11);
          font-weight: $fw_4;
        }

        .table-project-create-date {
          .create-date {
            display: block;
            font-weight: $fw_4;
            font-size: rem(12);
            line-height: rem(16);
            color: $col_gray-600;
            margin-bottom: rem(4);
          }
        }

        .table-project-info {
          position: relative;
          width: fit-content;

          .table-project-total-items {
            background-color: $col_gray-300;
            border-radius: rem(4);
            box-sizing: border-box;
            color: $col_black;
            display: flex;
            padding: rem(3) rem(10);
            cursor: default;
          }

          &:hover {
            .table-project-info-more {
              display: block;
            }

            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: rem(16);
            }

            &::after {
              top: unset !important;
              bottom: 100%;
            }
          }

          .table-project-info-more {
            display: none;
            position: absolute;
            padding: rem(24);
            width: rem(346);
            height: fit-content;
            border-radius: rem(8);
            box-shadow: $box_shadow_icon;
            background-color: $col_white;
            border: rem(1) solid $col_gray-10;
            margin-top: rem(15);
            z-index: 100;

            .table-project-info-header {
              margin-bottom: rem(24);

              img {
                height: rem(24);
                box-sizing: border-box;
                border-radius: rem(5);
                margin-right: rem(12);
              }

              .project-name {
                font-size: rem(24);
                font-weight: $fw_4;
                white-space: nowrap;
                @include text-ellipsis;
              }
            }

            dl {
              &:last-child {
                margin-bottom: 0;
              }
              
              dt, dd {
                font-size: rem(16);
                line-height: rem(24);
              }
            }
          }
        }

        .table-project-status {
          padding: rem(6) rem(12);
          border-radius: rem(4);
          display: flex;
          width: fit-content;

          span {
            margin: auto;
          }
        }

        .table-project-status-active {
          background-color: rgba(98, 181, 143, 0.15);
          border: rem(1) solid $col_green-300;
        }

        .table-project-status-pending {
          background-color: rgba(255, 197, 51, 0.15);
          border: rem(1) solid $col_sunglow;
        }

        .table-project-status-archived {
          background: rgba(242, 114, 111, 0.15);
          border: rem(1) solid $col_begonia;
        }

        .table-project-status-creating {
          background: rgba(21, 128, 239, 0.15);
          border: rem(1) solid $col_blue;
        }

        .table-project-action {
          position: relative;

          &-button {
            cursor: pointer;
          }

          .table-project-action-show {
            display: block;
            height: auto;
            width: rem(180);
            padding: rem(16);
            background-color: $col_white;
            position: absolute;
            box-shadow: $box_shadow;
            border-radius: rem(4);
            z-index: 100;
            size: rem(16);
            right: rem(10);
            margin-top: rem(10);

            .action-item {
              margin-bottom: rem(6);
              font-size: rem(16);
              font-weight: $fw_4;
              line-height: rem(24);
              color: $col_gray-600;
              cursor: pointer;

              &:last-child {
                margin-bottom: rem(0);
              }
            }
          }
        }

        &:nth-child(n + 10) {
          .table-project-user {
            .member-list ul {
              top: unset;
              bottom: calc(100% + rem(8));
            }
          }
        }
      }

      tr:nth-child(n+8) {
        .table-project-info-more {
          top: unset;
          bottom: calc(100% + rem(16));
        }
      }
    }

    th, td {
      &:nth-child(3) {
        max-width: rem(260);
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: rem(180);
      }
    }
  }
}

.project-management {
  :global(.activities-box) {
    min-height: rem(147);

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.projects) {
    margin-bottom: 0;
  }
}

@media print {
  .project-admin-body {
    padding: rem(16) rem(10) rem(26);

    table thead th {
      padding: rem(10) rem(0);
      @include text-center;

      a {
        justify-content: center;

        &::after {
          right: rem(-13);
          top: rem(5);
          border-left-width: rem(3);
          border-right-width: rem(3);
          border-top-width: rem(5);
        }

        &.sort-asc::after {
          border-top: none;
          border-bottom-width: rem(5);
        }
      }
    }
  }

  @-moz-document url-prefix() {
    .project-management {
      &-header {
        h2 {
          font-size: rem(18);
        }

        :global(.dropdown-react-select) {
          width: rem(200);
        }
      }
    }
  }
}