@import "src/assets/css/_base.scss";

.ai-search-wrap {
    padding: rem(16);
    background-color: $col_white;
    background: $col-white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.messages-area {
    @include scroll-flexible;
    flex: 1;
    overflow-y: auto;
    padding: rem(8) rem(4);
    display: flex;
    flex-direction: column;
    gap: rem(16);
}

.not-ready-area {
    display: flex;
    flex-direction: column;
    gap: rem(64);
    justify-content: center;
    height: 100%;
    padding: rem(8) rem(4);

    & span {
        text-align: center;
        font-size: rem(20);
        color: $col-gray;
    }
}

.no-messages-area {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    justify-content: flex-start;
    height: 100%;
    padding: rem(8) rem(4);

    & span {
        text-align: center;
        font-size: rem(20);
        color: $col-gray;
    }

    .prompt-suggestions {
        display: flex;
        flex-direction: column;
        gap: rem(8);
        align-items: flex-start;
    }
}

.messages-area-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.input-area {
    display: flex;
    gap: rem(12);
    padding: rem(16) 0;
    border-top: 1px solid $col-gray-200;
}

.message-input {
    flex: 1;
    padding: rem(8) rem(12);
    border: 1px solid $col-gray-300;
    border-radius: rem(6);
    font-size: rem(14);

    &:focus {
        outline: none;
        border-color: $col_blue;
    }
}

.submit-button {
    padding: rem(8) rem(16);
    background-color: $col-white;
    border: 1px solid $col-gray-300;
    border-radius: rem(6);
    cursor: pointer;
    font-size: rem(14);

    &:hover {
        opacity: 0.9;
    }

    & img {
        width: rem(24);
        height: rem(24);
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .spinner-loading {
        width: rem(24);
        height: rem(24);
        color: $col_blue;
    }
}

.header-controls {
    display: flex;
    gap: rem(4);
    padding: rem(0) rem(0) rem(16);
    justify-content: flex-end;
    border-bottom: 1px solid $col-gray-200;
    align-items: baseline;

    .new-chat-button {
        background-color: $col-white;
        cursor: pointer;
        border: none;
    
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        img {
            width: rem(20);
            height: rem(20);
            transition: filter 0.2s ease;
        }

        &:hover img, &:active img {
            filter: invert(31%) sepia(98%) saturate(1753%) hue-rotate(201deg) brightness(97%) contrast(107%);
        }
    }
}

.option-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}