@import "assets/css/_base.scss";

.modal-container {
  .modal-dialog {
    min-width: rem(432);

    .modal-content {
      padding: rem(24) 0;
      border-radius: rem(8);

      .modal-header {
        border: none;
        padding: 0 rem(24);

        div {
          font-size: rem(24);
          font-weight: $fw_5;
          color: $col_gray-600;
        }

        button {
          border: rem(1) solid $col_gray-600;
          opacity: 1;
          margin: 0;
          position: absolute;
          top: rem(16);
          right: rem(16);
        }
      }

      .modal-body {
        padding: rem(26) rem(24) rem(32);

        .checkbox-container {
          font-size: rem(14);
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: rem(14);
          cursor: default;

          .toolbar-input {
            appearance: none;
            width: rem(13);
            height: rem(13);
            border: 1px solid #ccc;
            border-radius: 3px;
            background-color: #f0f0f0;
            position: relative;
            cursor: pointer;
            transition: background-color 0.2s ease;
            display: inline-flex;
            align-items: center;
          }

          .toolbar-input:checked {
            background-color: #1580EF;
            border-color: #1580EF;
          }

          .toolbar-input:checked::before {
            content: '✔';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 8px;
          }

          .toolbar-input:disabled {
            background-color: #d3d3d3;
            border-color: #b0b0b0;
            cursor: default;
          }

          .toolbar-label {
            margin-left: rem(5);
          }
          }

          .unmapped-row {
            background-color: #fff8ee !important;
            border-radius: 4px;
          }

          .empty-record {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: rem(100);

            p {
              font-size: rem(16);
            }
        }

        .checkbox {
          span {
            margin-left: rem(26);
          }
        }

        p {
          font-size: rem(12);
          font-weight: $fw_5;
          margin-top: rem(10);
          color: $col_gray-400;
        }
      }

      .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: rem(24) rem(24) 0;

        .group-btn {
          display: flex;
          gap: rem(16);
        }
      }
    }
  }

  .modal-dialog-large {
    min-width: rem(850);
  }
}

:global { 
  .e-grid .e-toolbar {
    border-color: #e0e0e0;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-right-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  .e-grid .e-toolbar-items {
    border-top-right-radius: 0.8rem;
  }

  .e-list-item.e-active {
    color: #1580EF !important;
  }

  .e-grid .e-selectionbackground {
    background-color: #e3eef9 !important;
  }

  .e-list-item.e-disabled {
    pointer-events: none;
    opacity: 0.6;
  }  
}