@import "assets/css/_base.scss";

.preview-img-wrap {
  height: 100%;

  .cursor-pointer {
    cursor: pointer;
  }

  .h-100 {
    height: 100% !important;
  }

  .loading-blur {
    position: relative;
    height: 100%;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.preview-img-wrap-fr-tag {
  margin-right: rem(24);
}

@media print {
  .loading-blur {
    .img-review {
      position: absolute;
      top: 0;
      height: 100%;
    }
  }
}
