@import "assets/css/base";

.email-table {
  margin-top: rem(30) !important;
  padding: 0 !important;
  box-shadow: unset !important;
  height: 100% !important;
  min-height: 0 !important;
}

.is-result-preview-wrap {
  background: $col_white;
  box-shadow: $box_shadow;
  border-radius: rem(8);
  padding: rem(16);
  position: relative;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .is-result-preview-head {
    @include flex-between;

    .is-result-preview-select {
      width: 100%;
      @include flex-start;
      align-items: center;

      .is-result-preview-title {
        font-weight: $fw_5;
        font-size: rem(18);
        color: $col_gray-600;
        padding-right: rem(16);
      }

      .is-result-preview-dropdown {
        width: rem(170);
        font-size: rem(14);
        z-index: 1000;
      }

      .img-go-to-origin {
        cursor: pointer;
      }

      &-filter {
        .search-wrapper .btn-input {
          height: rem(28);
          width: vw(187);
          font-size: rem(14);
          padding-right: rem(25);
        }

        .icon-search,
        .icon-clear {
          width: rem(16);
          height: rem(16);
        }
      }
    }

    .render-tag-container {
      @include flex-end;
      margin-right: rem(12);
      flex-wrap: wrap;
      flex: 1;

      .tag-thumb-item {
        width: rem(16);
        height: rem(16);
        border-radius: rem(2);
        display: block;
        margin-bottom: rem(12);
        & + .tag-thumb-item {
          margin-left: rem(12);
        }
      }
    }
    .origin-button {
      width: rem(92);
    }

    .is-result-preview-search {
      input {
        height: rem(28);
      }
    }
  }

  .message-detail {
    background-color: $col_white;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    margin-top: rem(10);
    border: rem(1) solid $col_gray-800;
    box-shadow: none !important;
    border-radius: rem(8);
    height: 100%;
    position: relative;

    .message-body {
      padding: rem(10);
      height: 100%;

      .loading-preview,
      .executing-tag {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.no-padding {
        padding: 0;
      }
    }

    &.no-border {
      border: none;
    }
  }
  .executing-tag {
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: $background-faded;
    position: absolute;
    @include flex-between;
  }

  .message-detail {
    @include scroll-flexible;
  }

  // more styles
  .label-title {
    font-size: rem(16);
  }

  .btn {
    font-size: rem(14);
    border-radius: rem(4);
    border-width: rem(1);
    background-color: $col_white;

    &::after {
      content: none;
    }

    .btn-select-tag {
      margin-right: rem(40);
    }

    .btn-select-tag,
    .btn--select-tagall,
    .btn--select-clear {
      color: $col_gray;

      &.active {
        color: $col_blue;
      }
    }
  }

  .dropdown-item {
    width: auto;
    padding: 0;
    margin-right: rem(16);
  }

  .btn-select {
    width: rem(73);
    height: rem(32);
    font-weight: $fw_5;
    border-color: $col_blue;
    color: $col_blue;

    &:hover {
      background-color: unset;
      color: $col_blue;
    }
  }

  .btn-select-cancel {
    height: rem(32);
    font-weight: $fw_5;
    color: $col_white;
    border-color: $col_blue;
    background-color: $col_blue;
    margin-right: rem(40);
  }

  .btn-select-cancel-pop-up {
    height: rem(32);
    font-weight: $fw_5;
    color: $col_blue;
    border-color: $col_blue;
    background-color: $col_white;
    border: rem(1) solid $col_blue;
    min-width: rem(64);
    @include text-center;
  }

  .btn-select-save {
    height: rem(32);
    color: $col_gray;
    background-color: $col_gray-300;
    border: rem(1) solid $col_gray-300;
    min-width: rem(64);
    @include text-center;
  }

  .btn-select-save.active {
    color: $col_white;
    background-color: $col_blue;
  }

  .btn-select-tagcancel {
    &:hover {
      background-color: unset;
    }
  }

  .btn-tag-selected {
    margin-bottom: rem(10);

    .btn-outline-primary {
      border-radius: rem(4);
    }
  }

  .btn-select-tag,
  .btn-select-untag {
    height: rem(32);
    background-color: $col_white;
    border: rem(1) solid $col_gray-700;
    font-weight: $fw_5;
    color: $col_gray;
  }

  .btn-select-tag.active,
  .btn-select-untag.active {
    background-color: $col_white !important;
    border: rem(1) solid $col_blue !important;
    color: $col_blue;
  }

  .btn-select-tagall {
    height: rem(32);
    background-color: $col_white;
    border: rem(1) solid $col_gray-700;
    font-weight: $fw_5;
    color: $col_gray;
  }

  .btn-select-tagall.active {
    border: rem(1) solid $col_blue;
    color: $col_blue;
  }

  .btn-select-tag.active {
    background-color: unset;
    border: none;
  }

  .select-tag {
    display: flex;
    justify-content: space-between;

    .tag-container {
      display: flex;

      .btn-select-tag {
        margin-right: rem(8);

        &::after {
          content: none !important;
        }
      }

      .btn-select-tag.active {
        color: $col_blue !important;
      }
    }
  }

  .select-tag-items {
    display: flex;
  }

  .btn-change-message {
    display: flex;
    font-size: 0;
    justify-content: flex-end;
    flex: 1;

    .btn {
      width: rem(32);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: unset !important;
      }
    }

    :global(.btn-common button) {
      padding: 0;
      height: rem(32);
      width: rem(32);
      margin-left: rem(9);
    }
  }

  .btn-message-prev,
  .btn-message-next {
    background-color: red;
    border: rem(1) solid $col_blue;
    height: rem(32);
    width: rem(32);
    padding: 0;
    border-radius: rem(4);

    &:hover {
      background-color: unset;
    }
  }

  .dropdown-menu {
    min-width: rem(240) !important;
    max-width: rem(360);
    box-shadow: $box_shadow;
    border-radius: rem(4);
    border: none;
    padding-bottom: 0;
    max-height: rem(556);
    overflow-y: auto;

    @include scroll-flexible;

    &-select {
      margin: 0 rem(16) rem(16);

      &-item {
        @include flex-center;
        padding-top: rem(5);
        padding-bottom: rem(5);
      }

      span {
        width: rem(16);
        height: rem(16);
        display: block;
        border-radius: rem(2);
        margin-right: rem(10);
      }

      p {
        flex: 1;
        font-size: rem(14);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-action {
      border-top: rem(1) solid rgba($col_gray-700, 0.5);

      a {
        width: auto;
        padding: 0;
        margin-right: rem(16);

        button {
          width: rem(84);
          height: rem(32);
        }
      }
    }
  }

  .form-check-input {
    width: rem(13);
    height: rem(13);
    margin-left: rem(4);
  }
}

// ======== Use for preview search

.preview-wrap {
  max-height: rem(500);
  min-height: rem(500);
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border: rem(1) solid rgba(211, 211, 211, 0.5);

  .select-tag {
    display: none;
  }

  .message-detail {
    height: 100%;
  }
}

@media print {
  .is-result-preview-wrap {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    height: 100%;

    .message-detail {
      flex: 1;
      width: 100%;
      overflow: hidden;
    }

    .is-result-preview-head,
    .select-tag {
      width: 100%;
    }
  }
}

.viewer-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
