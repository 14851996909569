@import 'BaseAttachmentRenderer.module.scss';

.pdf-renderer {
    padding: rem(2);
    height: 100%;
    overflow: hidden;
}

.pdf-renderer-hidden {
    visibility: hidden;
}
