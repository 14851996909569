@import "assets/css/base.scss";

.avatar-header {
  position: relative;
  width: rem(40);
  height: rem(40);
  border-radius: rem(4);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header {
  height: rem(72);
  background-color: $col_branding_streem_blue; // Set background color
  color: $col_white; // Set text color to white
  
  .logo-container {
    display: flex;
    align-items: center;
    padding-left: rem(40);
    width: 30%;

    figure {
      width: rem(24);
      height: rem(24);
      position: relative;

      .menu-box {
        position: absolute;
      }
    }

    .logo {
      width: rem(127);
      height: rem(28);
      margin-left: rem(10);
    }
  }

  .header-nav {
    @include flex-between;
  }

  &-nav {
    ul.header-nav--ul li a.active-subtitle {
      color: $col_branding_fucsia;
      font-weight: $fw_7 !important;
    }
  }

  .disabled-link {
    pointer-events: none;
  }

  .profile-container {
    width: 30%;
    justify-content: flex-end;
    .matter-wrap {
      @include flex-between;
      margin-right: rem(30);
      font-size: rem(16);
      z-index: 1000;
      color: $col_blue;
    }
  }
}
