@import "assets/css/_base.scss";

.attachment-review-modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: none !important;
      margin: 0 auto;
      height: rem(962);
    }
  }

  @media (min-width: 1024px) {

    .modal-lg,
    .modal-xl {
      max-width: none !important;
    }
  }

  .modal-content {
    max-width: 80% !important;
    height: rem(962) !important;
    margin: 0 auto;
  }

  .loading-map {
    @include text-center;
    min-height: rem(50);
  }

  .btn-close {
    border: rem(1) solid $col_gray-600;
    border-radius: rem(4);
    position: absolute;
    top: rem(16);
    right: rem(16);
  }

  .modal-header {
    padding-bottom: rem(16) !important;
    border-bottom: unset;
  }

  .attachment-modal-body {
    padding: 0 !important;
    overflow: hidden;
  }

  .modal-footer {
    padding-top: rem(16) !important;
    border: unset;

    >* {
      margin: 0 0 0 rem(16);
    }
  }

  .attachment-review-title {
    font-size: rem(18);
    font-weight: $fw_5;
    color: $col_gray-600;
  }

  .col {
    padding: 0;

    &:first-child {
      margin-right: rem(24);
    }
  }

  .attachment-content {
    margin: 0 rem(16);
    display: flex;
    align-items: stretch;
    height: 100%;

    .attachment-info {
      margin-bottom: rem(8);
      flex-basis: 25%;
      max-width: 25%;

      .info-detail {
        border-style: solid;
        border-width: rem(1);
        border-color: $col_gray-300;
        border-radius: rem(8);
        box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
        padding: rem(16);
        min-height: 100%;

        &-label {
          font-weight: $fw_7;
          font-size: rem(12);
          margin-top: rem(2);
          margin-bottom: rem(8);
        }

        dl {
          font-size: rem(14);
          color: $col_gray-600;
          justify-content: flex-start;

          dd {
            flex: 1;
          }
        }
      }
    }

    .preview-tabs {
      flex-basis: 75%;
      max-width: 75%;
      display: flex;
      flex-direction: column;
      height: 100%;

      .preview-tabs-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;

        .preview-tabs-header-item {
          display: flex;
          align-items: stretch;
          gap: rem(4);

          &-dropdown {
            min-width: rem(160);
            font-size: rem(14);
            z-index: 1000;
          }
        }
      }

      .attachment-tab {
        color: $col_gray-600;
        display: flex;
        align-items: stretch;
        border-style: solid;
        border-width: rem(1);
        border-color: $col_gray-300;
        border-radius: rem(8);
        box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
        margin: rem(8) 0;
        height: calc(100% - rem(48));
        overflow: hidden;
        
        .azure-map {
          width: 100%;
          height: rem(350) !important;
          border-radius: rem(8);
        }

        .attachment_img-view {
          position: relative;
          width: 100%;
          border-radius: rem(8);
          background-color: $col_dark-charcoal;

          figure img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding-bottom: 0;
          }

          .blur-action {
            position: absolute;
            top: calc(100% - rem(34));
            left: rem(10);
            z-index: 999;
            background-color: $col_white;
            width: rem(24);
            height: rem(24);
            padding: rem(2);
            box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
            border-radius: rem(4);
            cursor: pointer;
          }

          .blur-img {
            filter: blur(rem(5));
          }
        }

        .attachment_application {
          width: 100%;
          object-fit: contain;
          padding: rem(8);
        }

        .attachment-video {
          width: 100%;
          border-radius: rem(4);
          object-fit: contain;
          position: relative;
        }

        .unknown-type {
          p {
            font-weight: $fw_4;
            font-size: rem(16);
          }
        }
      }

      .text-tab {
        color: $col_gray-600;
        display: flex;
        align-items: stretch;
        margin: rem(8) 0;
        height: rem(892);
        overflow: hidden;

        .text-content {
          width: 100%;
          min-height: 100% !important;
        }
      }

      .location-info-tab {
        border-style: solid;
        border-width: rem(1);
        border-color: $col_gray-300;
        border-radius: rem(8);
        box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
        padding: rem(16);
        display: flex;
        flex-direction: column;
        height: rem(892);
        margin: rem(8) 0;

        .location-info-map {
          min-height: 60%;
          border-radius: rem(8);
        }

        .location-info-detail {
          padding: rem(16);

          &-label {
            font-weight: $fw_7;
            font-size: rem(12);
            margin-top: rem(2);
            margin-bottom: rem(8);
          }

          dl {
            font-size: rem(14);
            color: $col_gray-600;
            justify-content: flex-start;

            dd {
              flex: 1;
            }
          }
        }

        .location-not-found {
          @include text-center;
          color: $col_gray-600;
          padding: rem(16);
          margin-top: 10%;

          img {
            width: rem(260);
            height: rem(260);
          }

          p {
            margin-top: rem(8);
            font-size: rem(24);
          }
        }
      }
    }
  }
}