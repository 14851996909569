@import "assets/css/base";

.preview-not-available {
  @include text-center;
  color: $col_gray-600;
  padding: rem(16);
  margin-top: 10%;

  img {
    width: rem(260);
    height: rem(260);
  }

  p {
    margin-top: rem(8);
    font-size: rem(24);
  }
}